import { cloneElement, isValidElement, Children } from 'react'
import { usePathname, useRouter } from './util/contexts'

import type { FunctionComponent } from 'react'
import type { SwitchProps, Match } from './types'


const Switch: FunctionComponent<SwitchProps> = (props) => {
  const { children, location, onChange } = props

  const { matcher } = useRouter()
  const originalPathname = usePathname()

  const nodesToRender = []
  let match: Match

  const matchRoutes = (children) => {
    Children.toArray(children).some((child) => {
      if (match) {
        return true
      }

      if (isValidElement(child)) {
        if (child.props.path) {
          if (child.props.path === '*') {
            match = { path: '*', params: {} }
            nodesToRender.push(child)
            return
          }

          const result = matcher(child.props.path, location || originalPathname)

          // if pathname match route path then add Route to nodesToRender
          if (result) {
            match = result
            nodesToRender.push(child)
          }
        }
        else {
          // if node is Layout then add it to nodesToRender
          nodesToRender.push(child)

          // and iterate again
          matchRoutes(child.props.children)

          // if in nested routes no match then remove added Layout (move up by tree)
          if (!match) {
            nodesToRender.pop()
          }
        }
      }
    })
  }

  matchRoutes(children)

  if (match) {
    if (typeof onChange === 'function') {
      onChange(match)
    }

    return nodesToRender.reverse().reduce((result, node) => {
      // render route
      if (!result) {
        return cloneElement(node, { match })
      }

      // render layout
      return cloneElement(node, { match }, result)
    }, null)
  }

  return null
}


export default Switch
