import { pathToRegexp } from 'path-to-regexp'

import { MatcherFn } from '../types'


type MakeRegexpFn = (pattern: string) => {
  keys: { name: string }[]
  regexp: RegExp
}

export default function createMatcher(): MatcherFn {
  const cache = {}

  // obtains a cached regexp version of the pattern
  const getRegexp: MakeRegexpFn = (pattern) => {
    if (!cache[pattern]) {
      const keys = []
      const regexp = pathToRegexp(pattern, keys)

      cache[pattern] = {
        regexp,
        keys,
      }
    }

    return cache[pattern]
  }

  return (pattern, path) => {
    const { regexp, keys } = getRegexp(pattern || '')
    const out = regexp.exec(path)

    if (!out) {
      return null
    }

    // formats an object with matched params
    const params = keys.reduce((params, key, i) => {
      params[key.name] = out[i + 1]
      return params
    }, {})

    return {
      path: pattern,
      params,
    }
  }
}
