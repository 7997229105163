import React from 'react'
import { useRef } from 'react'

import useLocationHook from './util/useLocationHook'
import createMatcher from './util/createMatcher'
import createHistory from './util/createHistory'
import { PathnameContext, RouterContext, SearchParamsContext, PrevLocationContext } from './util/contexts'

import type { RouterProps, RouterData } from './types'


const buildRouter = (opts: RouterProps = {}): RouterData => ({
  locationHook: opts.locationHook || useLocationHook,
  history: opts.history || createHistory(),
  matcher: createMatcher(),
})

const Router: React.FC<RouterProps> = (props) => {
  const ref = useRef<RouterData>(null)

  if (!ref.current) {
    ref.current = buildRouter(props)
  }

  const { pathname, searchParams, prevLocation } = ref.current.locationHook()

  return (
    <RouterContext.Provider value={ref.current}>
      <PathnameContext.Provider value={pathname}>
        <SearchParamsContext.Provider value={searchParams}>
          <PrevLocationContext.Provider value={prevLocation}>
            {props.children}
          </PrevLocationContext.Provider>
        </SearchParamsContext.Provider>
      </PathnameContext.Provider>
    </RouterContext.Provider>
  )
}


export default Router
