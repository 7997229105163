import { createContext, useContext } from 'react'

import type { RouterData, Pathname, Params, PrevLocation } from '../types'


export const RouterContext = createContext<RouterData>(null)
export const useRouter = () => useContext(RouterContext)
export const useHistory = () => useContext(RouterContext).history

export const PathnameContext = createContext<Pathname>('')
export const usePathname = () => useContext(PathnameContext)

export const SearchParamsContext = createContext<Params>({})
export const useSearchParams = () => useContext(SearchParamsContext)

export const PrevLocationContext = createContext<PrevLocation>('')
export const usePrevLocation = () => useContext(PrevLocationContext)

export const RouteParamsContext = createContext<Params>({})
export const useParams = () => useContext(RouteParamsContext)
