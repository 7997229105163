import React from 'react'

import { RouteParamsContext } from './util/contexts'
import type { RouteProps } from './types'


const Route: React.FC<RouteProps> = ({ children, path, match, component: Component, ...props }) => {
  if (!match) {
    return null
  }

  // `props.match` is present - Route is controlled by the Switch
  const { params } = match

  let routeChildren

  // React-Router style `component` prop
  if (Component) {
    routeChildren = (
      <Component {...props} />
    )
  }
  else {
    routeChildren = children
  }

  return (
    <RouteParamsContext.Provider value={params}>
      {routeChildren}
    </RouteParamsContext.Provider>
  )
}


export default Route
