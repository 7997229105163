import React, { useRef } from 'react'
import { useHistory } from './util/contexts'

import type { RedirectProps } from './types'


const Redirect: React.FC<RedirectProps> = ({ /* path, */ to }) => {
  const history = useHistory()
  const ref = useRef(false)

  if (!ref.current) {
    ref.current = true
    history.replace(to)
  }

  return null
}


export default Redirect
